import { Alert, AlertIcon, Button, Flex, Heading, Link, Spinner, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { ACTION_TYPE } from '../../constants/enums';
import EventView from './EventView';

interface Props {
  actionName: ACTION_TYPE;
  confirmAction: () => void;
}

export default function ConfirmAction(props: Props) {
  const { actionName, confirmAction } = props;

  const [goBack, setGoBack] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const t = useTranslations('BossHub.confirmAction');

  function onClickCancelAction() {
    setGoBack(true);
  }

  async function onClickConfirmAction() {
    setLoading(true);
    confirmAction();
  }

  if (loading) {
    return (
      <Flex
        direction="column"
        h="46.25rem"
        gap="2.265rem"
        alignItems="center"
        justifyContent="center"
        data-test-id="loading-spinner"
      >
        <Heading size="md">{t(`loading.${actionName}`)}</Heading>
        <Spinner
          thickness="6px"
          speed="0.65s"
          emptyColor="gray.100"
          color="pink.500"
          h="80px"
          w="80px"
        />
      </Flex>
    );
  }

  return goBack ? (
    <EventView />
  ) : (
    <Flex
      gap="0.75rem"
      direction="column"
      h="46.25rem"
      w="80%"
      alignItems="center"
      justifyContent="center"
      data-test-id="confirm-action"
    >
      <Heading size="md" data-test-id="confirm-action-heading" textAlign="center">
        {t(`heading.${actionName}`)}
      </Heading>
      <Alert
        w={{ lg: '60%' }}
        bgColor="blue.50"
        borderRadius="0.375rem"
        fontWeight="400"
        data-test-id="refund-warning-message"
      >
        <AlertIcon />
        {t('refundReminder')}
      </Alert>
      <Text fontSize="1rem" data-test-id="checkout-roadmap">
        {`${t('checkoutRoadmapMessage')} `}
        <Link color="pink.500" href="" data-test-id="roadmap-link">
          {t('readMore')}
        </Link>
      </Text>
      <Flex mt="1.875" gap="0.75rem">
        <Button
          variant="outline"
          colorScheme="black"
          borderColor="gray.200"
          textTransform="capitalize"
          onClick={onClickCancelAction}
          data-test-id="cancel-action-button"
        >
          {t(`cancelActionButton.${actionName}`)}
        </Button>
        <Button
          colorScheme="pink"
          onClick={onClickConfirmAction}
          data-test-id="confirm-action-button"
        >
          {t(`confirmActionButton.${actionName}`)}
        </Button>
      </Flex>
    </Flex>
  );
}
